import { reactive, ref } from 'vue';
import fetchFunction from './fetchFunction';

const state = reactive({
  year: null,
  month: null,
  date: null,
  today: {
    year: null,
    month: null,
    date: null,
  },
  checkInContent: 'dateOfAppointment',
  updateAppointmentData: [],
  clinicPeriodLastTime: {},
  calendarContent: 'continuousOutpatientAmount',
  department: 'AestheticMedicine',
  // appointmentContent: 'dateOfAppointment',
  couseReason: '',
  timeRange: [],
  filterQueryStatus: {
    continuousOutpatientNum: 1,
    isAppointment: true,
    morning: true,
    afternoon: true,
    evening: true,
  },
  customerStoredValueData: {},
  voucherDepositData: {},
  offWorkTime: '',
  // data
  calendarData: [],
  doctorData: [],
  alternateCustomerData: [],
  daysOutpatientData: [],
  bodyPartOption: [],

  selectedDoctor: [],
  showCalendarData: [],
  calendar: [],
});

/** 客戶查詢 */
async function searchDetailedInformation(customer) {

  const {name, birthday, cellPhone } = customer;
  
  const date = `${state.year}-${state.month + 1 <= 9 ?'0':''}${state.month + 1}-${state.date <= 9 ?'0':''}${state.date}`;

  let url = `/api/MedicalRecord/GetDoctorAttendanceDataBySearchCondition?departmentId=${state.department}&customerName=${name}&customerBirthday=${birthday}&customerCellPhone=${cellPhone}&date=${date}&clinicPeriods=Morning&clinicPeriods=Afternoon&clinicPeriods=Evening`;

  if (state.department === 'Beauty') {
    url = `/api/MedicalRecord/GetDepartmentAttendanceDataBySearchCondition?departmentId=${state.department}&customerName=${name}&customerBirthday=${birthday}&customerCellPhone=${cellPhone}&date=${date}&clinicPeriods=Morning&clinicPeriods=Afternoon&clinicPeriods=Evening`;
  }

  state.selectedDoctor.forEach(item => {
    url = `${url}&doctors=${item.id}`;
  });

  const token = fetchFunction.getTokenFromCookie();

  const options = {
    headers: {
      'Content-Type': 'application/json',
      "Authorization" : `Bearer ${token}`
    },
    method: 'GET'
  }

  const response = await fetchFunction.fetchApi(url, options);

  detailedInformationInit(response.data);
}

/** 詳細到診資料 */
const detailedinformationData = ref([]);
    
/** 醫美部 - 取得詳細到診 */
async function getDoctorAttendanceData() {
  const date = `${state.year}-${state.month + 1 <= 9 ?'0':''}${state.month + 1}-${state.date <= 9 ?'0':''}${state.date}`;
  
  let url = `/api/MedicalRecord/GetDoctorAttendanceData?departmentId=${state.department}&date=${date}`;

  state.selectedDoctor.forEach(item => {
    url = `${url}&doctors=${item.id}`;
  });

  const token = fetchFunction.getTokenFromCookie();

  const options = {
    headers: {
      'Content-Type': 'application/json',
      "Authorization" : `Bearer ${token}`
    },
    method: 'GET'
  }

  const response = await fetchFunction.fetchApi(url, options);

  detailedInformationInit(response.data);
  
  state.bodyPartOption = transferDoctorAttendanceDataToBodyPartOption(response.data);

}

function transferDoctorAttendanceDataToBodyPartOption(data){
  return data.map(item => item.bodyPartOptions)

}

/** 美容部 - 取得詳細到診 */
async function getDepartmentAttendanceData() {
  const date = `${state.year}-${state.month + 1 <= 9 ?'0':''}${state.month + 1}-${state.date <= 9 ?'0':''}${state.date}`;
  
  let url = `/api/MedicalRecord/GetDepartmentAttendanceData?departmentId=${state.department}&date=${date}`;

  const token = fetchFunction.getTokenFromCookie();

  const options = {
    headers: {
      'Content-Type': 'application/json',
      "Authorization" : `Bearer ${token}`
    },
    method: 'GET'
  }

  const response = await fetchFunction.fetchApi(url, options);

  detailedInformationInit(response.data);

  return response.data  
}



/** 詳細到診 - 初始 */
function detailedInformationInit(response) {

  let doctorAttendanceData = response;
  const customerIdArr = [];
  const data = [];
  
  if (!doctorAttendanceData) return null;

  let morning = state.filterQueryStatus.morning ? '早診' : null;
  let afternoon = state.filterQueryStatus.afternoon ? '午診' : null;
  let evening = state.filterQueryStatus.evening ? '晚診' : null;

  doctorAttendanceData = doctorAttendanceData.filter(item => {
    const clinicPeriodName = item.attendanceMedicalRecord.clinicPeriodName;
    return clinicPeriodName === morning || clinicPeriodName === afternoon || clinicPeriodName === evening;
  });

  doctorAttendanceData.forEach(item => {
    if (!customerIdArr.includes(item.attendanceMedicalRecord.customerId)) {
      customerIdArr.push(item.attendanceMedicalRecord.customerId);
      data.push({
          customer: {
            customerId: item.attendanceMedicalRecord.customerId,
            beauticianNickname: item.attendanceMedicalRecord.beauticianNickname,
            customerName: item.attendanceMedicalRecord.customerName,
            customerBirthday: item.attendanceMedicalRecord.customerBirthday,
          },
          attendanceMedicalRecord: [],
        });
    }
  })

  doctorAttendanceData.forEach(itemA => {
    const detailItem = {
      cureContent: {
        ...itemA.attendanceMedicalRecord,
      },
      voucherUsageRecords: [],
      bodyPart: [],
      beautyConsumableName: itemA.attendanceMedicalRecord.beautyConsumableName,
      orderDetails: [],
      totalExpense: 0,
      oweVoucherSituationData: [],
      couponUsageRecords: []
    }
    
    // 麻藥費用整理
    // 不上麻不用顯示
    // 改不上麻
    if (itemA.attendanceMedicalRecord.anesthesiaSituation === "CancelAnesthesia") {
      detailItem.orderDetails.push({
        chargeType: 'anesthesia',
        chargeName: '麻藥費用 (改不上麻)',
        anesthesiaSituation: "CancelAnesthesia",
        price: 0,
        received: true,
      })
    }

    // 上麻
    if (itemA.attendanceMedicalRecord.anesthesiaSituation === "Anesthesia") {
      detailItem.orderDetails.push({
        chargeType: 'anesthesia',
        chargeName: '麻藥費用',
        anesthesiaSituation: "Anesthesia",
        price: itemA.anestheticOrderDetail.price === 0 ? '免費' : itemA.anestheticOrderDetail.price,
        received: itemA.anestheticOrderDetail.received,
      })
      detailItem.totalExpense += itemA.anestheticOrderDetail.price 
    }

    const experienceData = {
      chargeType: 'cash',
      chargeName: '體驗費用',
      bodyParts: [],
      price: [],
      received: [],
    }

    const depositData = {
      chargeType: 'cash',
      chargeName: '押金費用',
      bodyParts: [],
      price: [],
      received: [],
    }

    const onceData = {
      chargeType: 'cash',
      chargeName: '單次費用',
      bodyParts: [],
      price: [],
      received: [],
    }

    // 現金支付
    itemA.coursePartOrderDetails.forEach(itemB => {

      detailItem.totalExpense += parseInt(itemB.price);
      // 體驗費用 - 初始
      if (itemB.priceType === 'Experience') {
        let bodyPart = itemA.bodyPartOptions.find(itemC => itemC.id === itemB.bodyPartId);
        if (itemA.attendanceMedicalRecord.courseTypeId === 'IV') {
          bodyPart = {
            name: 'IV',
          }
        }
        experienceData.bodyParts.push(bodyPart);
        experienceData.price.push(itemB.price);
        experienceData.received.push(itemB.received);
        return null;
      }

      // 押金費用 - 初始
      if (itemB.priceType === 'Deposit') {
        let bodyPart = itemA.bodyPartOptions.find(itemC => itemC.id === itemB.bodyPartId);
        if (itemA.attendanceMedicalRecord.courseTypeId === 'IV') {
          bodyPart = {
            name: 'IV',
          }
        }
        depositData.bodyParts.push(bodyPart);
        depositData.price.push(itemB.price);
        depositData.received.push(itemB.received);
        return null;
      }

      // 單次費用 - 初始
      if (itemB.priceType === 'Once') {
        if (itemB.bodyPartId === 'Other') {
          onceData.bodyParts.push({
            id: itemB.bodyPartId,
            name: itemB.otherPartName,
          });
          onceData.price.push(itemB.price);
          onceData.received.push(itemB.received);
          return null;
        }

        let bodyPart = itemA.bodyPartOptions.find(itemC => itemC.id === itemB.bodyPartId);
        if (itemA.attendanceMedicalRecord.courseTypeId === 'IV') {
          bodyPart = {
            name: 'IV',
          }
        }
        onceData.bodyParts.push(bodyPart);
        onceData.price.push(itemB.price);
        onceData.received.push(itemB.received);
        return null;
      }
    })

    if (experienceData.bodyParts.length !== 0) {
      detailItem.orderDetails.push(experienceData);
    }

    if (depositData.bodyParts.length !== 0) {
      detailItem.orderDetails.push(depositData);
    }

    if (onceData.bodyParts.length !== 0) {
      detailItem.orderDetails.push(onceData);
    }

    detailItem.bodyPart = itemA.bodyPartOptions.map(item => {
      const bodyPartItem = itemA.bodyParts.find(t => item.id === t.bodyPartId);

      if (bodyPartItem) {
        return {
          name: item.name,
          ...bodyPartItem,
          // itemA
        }
      }
    })

    // detailItem.bodyPart 資料整理
    // detailItem.bodyPart = itemA.bodyParts.map(item1 => {
    //   const bodyPartItem = itemA.bodyPartOptions.find(item2 => item2.id === item1.bodyPartId);

    //   return {
    //     name: bodyPartItem.name,
    //     ...item1
    //     // itemA
    //   }
    // });

    const allCoupons = [
      ...itemA.voucherUsageRecords,
      ...itemA.couponUsageRecords.map(item => ({ ...item, coursePartId: item.bodyPartId})),
      ...itemA.oweVoucherSituationData,
    ];
    
    // 療程卷號資料帶入預設位置 - 重複 id 中取唯一出來
    const uniqueId = allCoupons.filter((item, index, self) => {
      return index === self.findIndex((t) => t.coursePartId === item.coursePartId)
    })
    
    detailItem.voucherUsageRecords = uniqueId.map(item => {
      if (itemA.attendanceMedicalRecord.courseTypeName === '點滴') {
        return {
          bodyPartName: 'IV',
          bodyPartId: item.bodyPartId,
          voucherId: [],
        }
      }
      let bodyPartName = itemA.bodyPartOptions.find(itemB => itemB.id === item.coursePartId);
      
      return {
        bodyPartName: bodyPartName ? bodyPartName.name : null,
        bodyPartId: item.coursePartId,
        voucherId: [],
      }
    })
    
    detailItem.oweVoucherSituationData = allCoupons.map(item => {
      let bodyPartName = itemA.bodyPartOptions.find(itemB => itemB.id === item.coursePartId);
      if(item.oweAmount){
        return {
          bodyPartName: bodyPartName ? bodyPartName.name : null,
          bodyPartId: item.coursePartId,
          oweAmount: item.oweAmount,
        }
      }
      
    }).filter(item => item !== undefined);
    
    // 以 bodyPartId，整理欠卷
    itemA.oweVoucherSituationData.forEach(item1 => {

      const existingRecord = detailItem.voucherUsageRecords.find(e => e.bodyPartId === item1.coursePartId);
      let bodyPartName = itemA.bodyPartOptions.find(itemB => itemB.id === item1.coursePartId);

      if(!existingRecord){
        detailItem.voucherUsageRecords.push({
          bodyPartName: bodyPartName.name,
          bodyPartId: item1.coursePartId,
          oweAmount: item1.oweAmount 
      });
      }
    })

    // 以 coursePartId，整理療程卷號
    itemA.voucherUsageRecords.forEach(item1 => {
      
      detailItem.voucherUsageRecords.forEach(item2 => {
        if (item2.bodyPartId === item1.coursePartId) {
          item2.voucherId.push(item1.voucherId);

          return null;
        }
      })
    })

    // 以 bodyPartId，整理活動卷號
    // itemA.couponOrderDetails.forEach(item1 => {

    //   let couponNumber = item1.couponNumber.toString().padStart(3, 0);

    //   let volumeNumber = `${item1.activityVoucherId}${couponNumber}`;

    //   detailItem.voucherUsageRecords.forEach(item2 => {

    //     if (item2.coursePartId === item1.bodyPartId) {
    //       item2.voucherId.push(volumeNumber);
    //     }
    //   })
    // })
    
    // 以 bodyPartId，整理活動卷號
    detailItem.couponUsageRecords = allCoupons.map(item => {
      let activityName = itemA.prizeData.find(activityItem => activityItem.Id === item.prizeId);

      if(item.prizeId){
        return {
          bodyPartId: item.bodyPartId,
          activityName: activityName?.activityName,
          couponNumber: item.couponNumber,
          prizeLevel: activityName?.prizeLevel
        }
      }
    }).filter(item => item !== undefined);

    // itemA.couponUsageRecords.forEach(item1 => {
      // detailItem.couponUsageRecords = uniqueId.map(item => {
      //   let bodyPartName = itemA.bodyPartOptions.find(itemB => itemB.id === item1.bodyPartId);
      //   // itemA.prizeData
      //   return {
      //     bodyPartName: bodyPartName ? bodyPartName.name : null,
      //     bodyPartId: item.coursePartId,
          
      //   }
      // })
    // })
    
    // 預設醫師資料位置，並帶入 detailItem
    data.forEach(itemB => {
      if (itemB.customer.customerId === itemA.attendanceMedicalRecord.customerId) {

        if (itemB.attendanceMedicalRecord.length === 0) {
          itemB.attendanceMedicalRecord.push({
            operatorName: itemA.attendanceMedicalRecord.operatorName,
            operatorId: itemA.attendanceMedicalRecord.operatorId,
            detail: [detailItem],
          })

          return null;
        }

        const judge = itemB.attendanceMedicalRecord.some(itemC => itemC.operatorId === itemA.attendanceMedicalRecord.operatorId);

        if (!judge) {
          itemB.attendanceMedicalRecord.push({
              operatorName: itemA.attendanceMedicalRecord.operatorName,
              operatorId: itemA.attendanceMedicalRecord.operatorId,
              detail: [detailItem],
          })
        } else {
          itemB.attendanceMedicalRecord.forEach(itemD => {
            if (itemD.operatorId === itemA.attendanceMedicalRecord.operatorId) {
              itemD.detail.push(detailItem);
            }
          })
        }
      }
    })
  })

  // 資料排序
  
  // 資料層排序
  data.forEach(itemA => {
    // 客戶層
    itemA.attendanceMedicalRecord.forEach(itemB => {
      // 醫生層
      let arrive = [];
      let hasLeft = [];
      itemB.detail.forEach(itemC => {
        // 資料層
        if (itemC.cureContent.arrivedTime && !itemC.cureContent.leftTime) {
          arrive.push(itemC);
        }

        if (itemC.cureContent.arrivedTime && itemC.cureContent.leftTime) {
          hasLeft.push(itemC);
        }
      })

      arrive.sort((a, b) => {
        const splitA = a.cureContent.arrivedTime.split('T');
        const splitB = b.cureContent.arrivedTime.split('T');
        let timeA = new Date(`${splitA[0]} ${splitA[1]}`).getTime();
        let timeB = new Date(`${splitB[0]} ${splitB[1]}`).getTime();

        if (timeA > timeB) {
          return -1;
        }

        if (timeA < timeB) {
          return 1;
        }

        if (timeA === timeB) {
          return 0;
        }
      });

      hasLeft.sort((a, b) => {
        const splitA = a.cureContent.arrivedTime.split('T');
        const splitB = b.cureContent.arrivedTime.split('T');
        let timeA = new Date(`${splitA[0]} ${splitA[1]}`).getTime();
        let timeB = new Date(`${splitB[0]} ${splitB[1]}`).getTime();

        if (timeA > timeB) {
          return 1;
        }

        if (timeA < timeB) {
          return -1;
        }

        if (timeA === timeB) {
          return 0;
        }
      });

      itemB.detail = arrive.concat(hasLeft);
    })
  })

  // 醫生層排序
  data.forEach(itemA => {
    // 客戶層
    let arrive = [];
    let hasLeft = [];
    itemA.attendanceMedicalRecord.forEach(itemB => {
      // 醫生層

      if (itemB.detail[0].cureContent.arrivedTime && !itemB.detail[0].cureContent.leftTime) {
        arrive.push(itemB);
      }

      if (itemB.detail[0].cureContent.arrivedTime && itemB.detail[0].cureContent.leftTime) {
        hasLeft.push(itemB);
      }
    })

    arrive.sort((a, b) => {
      const splitA = a.detail[0].cureContent.arrivedTime.split('T');
      const splitB = b.detail[0].cureContent.arrivedTime.split('T');
      let timeA = new Date(`${splitA[0]} ${splitA[1]}`).getTime();
      let timeB = new Date(`${splitB[0]} ${splitB[1]}`).getTime();

      if (timeA > timeB) {
        return -1;
      }

      if (timeA < timeB) {
        return 1;
      }

      if (timeA === timeB) {
        return 0;
      }
    });

    hasLeft.sort((a, b) => {
      const splitA = a.detail[0].cureContent.arrivedTime.split('T');
      const splitB = b.detail[0].cureContent.arrivedTime.split('T');
      let timeA = new Date(`${splitA[0]} ${splitA[1]}`).getTime();
      let timeB = new Date(`${splitB[0]} ${splitB[1]}`).getTime();

      if (timeA > timeB) {
        return 1;
      }

      if (timeA < timeB) {
        return -1;
      }

      if (timeA === timeB) {
        return 0;
      }
    });
  
    itemA.attendanceMedicalRecord = arrive.concat(hasLeft);
  })

  // 客戶層排序
  let arrive = [];
  let hasLeft = [];

  data.forEach(itemA => {
    // 客戶層
    if (itemA.attendanceMedicalRecord[0].detail[0].cureContent.arrivedTime && !itemA.attendanceMedicalRecord[0].detail[0].cureContent.leftTime) {
      arrive.push(itemA);
    }

    if (itemA.attendanceMedicalRecord[0].detail[0].cureContent.arrivedTime && itemA.attendanceMedicalRecord[0].detail[0].cureContent.leftTime) {
      hasLeft.push(itemA);
    }
  })

  arrive.sort((a, b) => {
    const splitA = a.attendanceMedicalRecord[0].detail[0].cureContent.arrivedTime.split('T');
    const splitB = b.attendanceMedicalRecord[0].detail[0].cureContent.arrivedTime.split('T');
    let timeA = new Date(`${splitA[0]} ${splitA[1]}`).getTime();
    let timeB = new Date(`${splitB[0]} ${splitB[1]}`).getTime();

    if (timeA > timeB) {
      return -1;
    }

    if (timeA < timeB) {
      return 1;
    }

    if (timeA === timeB) {
      return 0;
    }
  });

  hasLeft.sort((a, b) => {
    const splitA = a.attendanceMedicalRecord[0].detail[0].cureContent.arrivedTime.split('T');
    const splitB = b.attendanceMedicalRecord[0].detail[0].cureContent.arrivedTime.split('T');
    let timeA = new Date(`${splitA[0]} ${splitA[1]}`).getTime();
    let timeB = new Date(`${splitB[0]} ${splitB[1]}`).getTime();

    if (timeA > timeB) {
      return 1;
    }

    if (timeA < timeB) {
      return -1;
    }

    if (timeA === timeB) {
      return 0;
    }
  });

  detailedinformationData.value = arrive.concat(hasLeft);
  // console.log(detailedinformationData.value)
}

/** 取得醫師資料 */
const getDoctors = async () => {
  let url = `/api/Employee/GetDoctorByActiveStatus?active=true`;

  const token = fetchFunction.getTokenFromCookie();

  const options = {
    headers: {
      'Content-Type': 'application/json',
      "Authorization" : `Bearer ${token}`
    },
    method: 'GET'
  }

  const response = await fetchFunction.fetchApi(url, options);

  if (response.statusCode >= 400) return null;

  let doctorData = response.data;

  const doctorDataLength = doctorData.length <= 4 ? doctorData.length : 4;

  for(let i = 0; i < doctorDataLength; i++) {
    doctorData[i].select = true;
  }
  state.doctorData = doctorData;
}

/** 取約診、候補的最新資料 */
const getUpdateAppointmentData = async(id) => {
  const url = `/api/Appointment/GetInitUpdateAppointmentData?id=${id}`;

  const token = fetchFunction.getTokenFromCookie();

  const options = {
    headers: {
      'Content-Type': 'application/json',
      "Authorization" : `Bearer ${token}`
    },
    method: 'GET'
  }

  const response = await fetchFunction.fetchApi(url, options);

  state.updateAppointmentData = response.data;
}

/** 取得/重製已選擇醫師 */
const resetSelectedDoctor = () => {
  state.selectedDoctor = [];

  state.doctorData.forEach(item => {
    if (item.select) {
      state.selectedDoctor.push(item);
    }
  });
}

/** 取日班表，醫美部與美容部 */
const getDaysOutpatientData = async() => {

  const date = `${state.year}-${state.month + 1 <= 9 ?'0':''}${state.month + 1}-${state.date <= 9 ?'0':''}${state.date}`;

  let url = `/api/Outpatient/GetDaysOutpatientData?departmentId=${state.department}&date=${date}${state.filterQueryStatus.morning ? `&clinicPeriods=Morning`: ''}${state.filterQueryStatus.afternoon ? `&clinicPeriods=Afternoon`: ''}${state.filterQueryStatus.evening ? `&clinicPeriods=Evening`: ''}`;

  state.selectedDoctor.forEach(item => {
    url = `${url}&doctors=${item.id}`;
  });

  const token = fetchFunction.getTokenFromCookie();

  const options = {
    headers: {
      'Content-Type': 'application/json',
      "Authorization" : `Bearer ${token}`
    },
    method: 'GET'
  }

  const response = await fetchFunction.fetchApi(url, options);

  state.daysOutpatientData = response.data;

  const { daysOutpatients, customerStoredValueData, voucherDepositData } = state.daysOutpatientData;

  state.customerStoredValueData = customerStoredValueData;
  state.voucherDepositData = voucherDepositData;
  
  // 取出顯示資料最後的時間

  const timeArr = Object.keys(daysOutpatients);

  if (timeArr.length === 0) return null; 

  // 時間間格
  const hourIntervals = timeArr[1].split(':')[0] - timeArr[0].split(':')[0];
  const minuteIntervals = timeArr[1].split(':')[1] - timeArr[0].split(':')[1];

  const lastData = timeArr[timeArr.length - 1].split(':');

  let time = new Date(0, 0, 0, lastData[0], lastData[1]);

  time.setHours(time.getHours() + hourIntervals);
  time.setMinutes(time.getMinutes() + minuteIntervals);

  state.offWorkTime = `${time.getHours()}:${time.getMinutes() <= 9 ? 0 : ''}${time.getMinutes()}`;

  // 取跨時段

  state.timeRange = [];

  if (state.department === 'AestheticMedicine') {
    for(let i = 0; i < timeArr.length; i++) {
      for(let j = 0; j < state.selectedDoctor.length; j++) {
        if(daysOutpatients[timeArr[i]][state.selectedDoctor[j].id].normalAppointment) {
          if(daysOutpatients[timeArr[i]][state.selectedDoctor[j].id].normalAppointment.timeRange > 1) {
  
            let itemRange = daysOutpatients[timeArr[i]][state.selectedDoctor[j].id].normalAppointment.timeRange;

            for(let k = i; k < (i + itemRange); k++) {
                state.timeRange.push({
                operatorId: daysOutpatients[timeArr[i]][state.selectedDoctor[j].id].normalAppointment.operatorId,
                time: timeArr[k],
                left: daysOutpatients[timeArr[i]][state.selectedDoctor[j].id].normalAppointment.left,
                lastTimeSlot: (k + 1) === (i + itemRange) ? true : false,
              })
            }
          }
        }
      }
    }
  }

  if (state.department === 'Beauty') {
    for(let i = 0; i < timeArr.length; i++) {
      if (daysOutpatients[timeArr[i]].normalAppointment) {
        if(daysOutpatients[timeArr[i]].normalAppointment.timeRange > 1) {
  
          let itemRange = daysOutpatients[timeArr[i]].normalAppointment.timeRange;

          for(let k = i; k < (i + itemRange); k++) {

            state.timeRange.push({
              operatorId: daysOutpatients[timeArr[i]].normalAppointment.operatorId,
              time: timeArr[k],
              left: daysOutpatients[timeArr[i]].normalAppointment.left,
              lastTimeSlot: (k + 1) === (i + itemRange) ? true : false,
            })
          }
        }
      }
    }
  }
}

/** 取今日日期 */
const getCurrentMonth = () => {
  const data = new Date();

  state.today = {
    month: data.getMonth() + 1,
    year: data.getFullYear(),
    date: data.getDate(),
  };
  state.month = data.getMonth(); // 0 - 11
  state.year = data.getFullYear();
  state.date = data.getDate();
}

/** 取得早、午診最後時間 */
const getClinicPeriodLastTime = async() => {
  const url = `/api/Outpatient/GetClinicPeriodLastTime?departmentId=${state.department}&date=${state.year}-${state.month + 1}-${state.date}`;

  const token = fetchFunction.getTokenFromCookie();

  const options = {
    headers: {
      'Content-Type': 'application/json',
      "Authorization" : `Bearer ${token}`
    },
    method: 'GET'
  }

  const response = await fetchFunction.fetchApi(url, options);

  state.clinicPeriodLastTime = response.data;
}

/** 顯示當月的總日，並把上、下個月的零碎日子加入 */
const showCalendar = (year, month) => {
  let currentMonth = getCalendar(year, month, true);

  const firstWeekLen = currentMonth[0].length;
  const lastWeekLen = currentMonth[currentMonth.length - 1].length;

  if (firstWeekLen < 7 && firstWeekLen !== 0) {
    let lastMonth = getCalendar(year, month - 1, false);
    
    currentMonth[0] = lastMonth[lastMonth.length - 1].concat(currentMonth[0]);
  }
  
  if (lastWeekLen < 7 && lastWeekLen !== 0) {
    let nextMonth = getCalendar(year, month + 1, false);
    currentMonth[currentMonth.length - 1] = currentMonth[currentMonth.length - 1].concat(nextMonth[0]);
  }

  for(let i = 0; i < currentMonth.length; i++) {
    if (currentMonth[i].length === 0) {
      currentMonth.splice(i, 1);
    }
  }

  // 不是內地，就刪除星期天
  // if (!this.china) {
  //   this.dayLength = 6;
  //   for(let i = 0; i < currentMonth.length; i++) {
  //     currentMonth[i].splice(0, 1);
  //   }
  // }

  state.calendar = currentMonth;
  state.calendarData = currentMonth;
}

/** 取得當月的總日和資料 */
const getCalendar = (year, month, j) => {
  let calendar = [];
  let week = [];
  let data = new Date(year, month + 1, 1);
 
  data.setHours(data.getHours() - 1);                // 下個月 - 1 小時，目的是轉換成當月的最後一天 

  const allDate = data.getDate();                    // 取得當月總日，例: 1 月，共 31 日

  for (let i = 1; i <= allDate; i++) {

    let morning = [];
    let afternoon = [];
    let evening = [];

    if (j) {
      for (let g = 0; g < state.showCalendarData.length; g++) {
        if (i === state.showCalendarData[g].day) {
          if (state.showCalendarData[g].clinicPeriod === 'Morning') {
            morning.push(state.showCalendarData[g]);
          }

          if (state.showCalendarData[g].clinicPeriod === 'Afternoon') {
            afternoon.push(state.showCalendarData[g]);
          }

          if (state.showCalendarData[g].clinicPeriod === 'Evening') {
            evening.push(state.showCalendarData[g]);
          }
        }
      }
    }

    let day = new Date(year, month, i).getDay(); // 星期 : 0 - 6
      if (month === 12) {
        month = 0;
        year += 1;
      }

      if (month === -1) {
        month = 11;
        year -= 1;
      }

      week.push({
        year: year,
        month: month + 1,
        date: i,
        day: day,
        isCurrentMonth: j,
        morning,
        afternoon,
        evening,
      });

      if (day === 6 || i === allDate) {
        calendar.push(week);
        week = [];
      }
  }
  return calendar;
}

/** 年和月的數字切換 */
const timeSwitch = (year, month, judge) => {
  if (judge) {
    month -= 1;
  } else {
    month += 1;
  }

  if (month === 12) {
    month = 0;
    year+= 1;
  }

  if (month === -1) {
    month = 11;
    year -= 1;
  }

  return { year, month };
}

/** 切換醫美部或美容部 - 約診與已約診 */
const continuousOrReservedToggle = () => {

  if (state.department === 'AestheticMedicine') {
    resetSelectedDoctor();

    if (state.checkInContent === 'dateOfAppointment') getDaysOutpatientData();

    if (state.checkInContent === 'detailedInformation') getDoctorAttendanceData();
  }

  if (state.department === 'Beauty') {
  
    if (state.checkInContent === 'dateOfAppointment') getDaysOutpatientData();

    if (state.checkInContent === 'detailedInformation') getDepartmentAttendanceData();
  }

  getClinicPeriodLastTime();
}


/** 候補時間轉換中文 */
const clinicPeriodToCh = (name) => {
  switch (name) {
    case 'Morning':
      return '早診';
    case 'Afternoon':
      return '午診';
    case 'Evening':
      return '晚診';
    case 'AllDay':
      return '全天';
  }
}

/** 轉換時間(分針)刪除秒的格式 */
const convertTimeFormatDeleteSecond = (time) => {
  return time.substr(0, 5);
}

/** 轉換時間(年月日)斜線格式 */
const convertTimeFormatSlash = (time) => {
  const str = time || '';
  return str.replace(/-/g, '/ ');
}

/** 部門切換 */
const departmentToggle = (status) => {
  state.department = status;
  continuousOrReservedToggle();
}

export default {
  state,
  detailedinformationData,
  getDoctors,
  getCurrentMonth,
  resetSelectedDoctor,
  showCalendar,
  getCalendar,
  timeSwitch,
  continuousOrReservedToggle,
  clinicPeriodToCh,
  convertTimeFormatDeleteSecond,
  convertTimeFormatSlash,
  departmentToggle,
  getDaysOutpatientData,
  getClinicPeriodLastTime,
  getUpdateAppointmentData,
  searchDetailedInformation,
  getDoctorAttendanceData,
};