import { reactive } from 'vue';
import fetchFunction from './fetchFunction';

const {
  getTokenFromCookie,
  fetchApi,
} = fetchFunction;

const leaveRecordState = reactive({
  leaveRecordTagData: [],
  initLeaveRecordData: [],
});

// GET

/** 取得離診紀錄的標籤資料 */
async function getLeaveRecordTagData(customerId, date) {

  const url = `/api/LeaveRecord/GetLeaveRecordTagData?customerId=${customerId}&appointmentDate=${date}`;

  const token = getTokenFromCookie();

  const options = {
    headers: {
      'Content-Type': 'application/json',
      "Authorization" : `Bearer ${token}`
    },
    method: 'GET'
  }

  const response = await fetchApi(url, options);

  leaveRecordState.leaveRecordTagData = response.data;
}

/** 取得初始化離診紀錄的資料 */
async function getInitLeaveRecordData(medicalRecordId) {
  const url = `/api/LeaveRecord/GetInitLeaveRecordData?medicalRecordId=${medicalRecordId}`;

  const token = getTokenFromCookie();

  const options = {
    headers: {
      'Content-Type': 'application/json',
      "Authorization" : `Bearer ${token}`
    },
    method: 'GET'
  }

  const response = await fetchApi(url, options);

  leaveRecordState.initLeaveRecordData = response.data;
  console.log(response.data);
}

// POST

/** 建立離診紀錄 */
async function createLeaveRecord(data) {
  const url = `/api/LeaveRecord`;

  const token = getTokenFromCookie();

  const options = {
    headers: {
      'Content-Type': 'application/json',
      "Authorization" : `Bearer ${token}`
    },
    method: 'POST',
    body: JSON.stringify(data),
  }

  return await fetchApi(url, options);
}

export default {
  leaveRecordState,
  getLeaveRecordTagData,
  getInitLeaveRecordData,
  createLeaveRecord,
};