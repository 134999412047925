import useAuthStore from '@/store/auth'

export function roleControlMiddleware(acceptRoles){
    const auth = useAuthStore()
    const { user } = auth

    if( !user?.role )  return {name: 'loginIndex'}
    
    const isRolePass = acceptRoles.some((role) => role === user.role)
    
    if(isRolePass)  return true
    else return {name: 'appointment'}
}
