import { reactive } from 'vue';
import fetchFunction from './fetchFunction';

const {
  getTokenFromCookie,
  fetchApi,
} = fetchFunction;

const courseState = reactive({
  courseOptionsByType: [],
});

// GET

/** 取得療程選項 */
async function getCourseOptionsByType(courseType) {

  const url = `/api/Course/GetCourseOptionsByType?courseTypeId=${courseType}`;

  const token = getTokenFromCookie();

  const options = {
    headers: {
      'Content-Type': 'application/json',
      "Authorization" : `Bearer ${token}`
    },
    method: 'GET'
  }

  const response = await fetchApi(url, options);

  courseState.courseOptionsByType = response.data;
}

export default {
  courseState,
  getCourseOptionsByType,
};