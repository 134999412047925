import useAuthStore from '@/store/auth'

export const useCheckUserIpIsHonchen = async (role) => {
  const auth = useAuthStore()
  const { postCheckUserIpIsHonchen } = auth

  if(role !== 'Administrator'){
    const isIpCorrect = await postCheckUserIpIsHonchen()
    if(!isIpCorrect) return false
    return true
  }
  return true
}

export const useCheckPermission = async () => {
  const { postPermission } = useAuthStore()

  try{
    const user = await postPermission()
    return user
  }
  catch(err){
    return null
  }
}

export const useAuthenticated = async () => {
  const { postCheckLogin } = useAuthStore()

  try{
    const res = await postCheckLogin()
    if(res.ok) return true
    else return false
  }
  catch(err){
    return false
  }
}

export const useCheckUserIsAlreadyLogin = () => {
  const { jwtToken, user } = useAuthStore()
  if(jwtToken && user)  return true
  return false
}