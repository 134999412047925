const accordionToggle = (num, toggleData) => {
  const toggleName = document.querySelectorAll(`${toggleData[0].className}`);

  let toggleIcon;
  let judge = false;

  if(toggleData.length > 1) {
    toggleIcon = document.querySelectorAll(`${toggleData[1].className}`);
    judge = true;
  }
  
  const offsetHeight = toggleName[num].offsetHeight;

  const len = toggleName.length;

  if (offsetHeight !== 0) {
    toggleName[num].style.maxHeight = `0px`;
    if (judge) {
      toggleIcon[num].style[`${toggleData[1].style}`] = toggleData[1].close;
    }
    return null;
  }
  
  if(toggleData[0].maxHeight) {
    toggleName[num].style.maxHeight = toggleData[0].maxHeight;
  } else {
    toggleName[num].style.maxHeight = toggleName[num].scrollHeight + 'px';
  }

  if (judge) {
    toggleIcon[num].style[`${toggleData[1].style}`] = toggleData[1].open;
  }

   for (let i = 0; i < len; i++) {
    if (i !== num) {
      toggleName[i].style.maxHeight = `0px`;
      if (judge) {
        toggleIcon[i].style[`${toggleData[1].style}`] = toggleData[1].close;
      }
    }
  }
}

export default {
  accordionToggle,
};