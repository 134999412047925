import router from "@/router";
import useAuth from '@/store/auth'
import { storeToRefs } from "pinia";
import useAlertMessage from './alertMessage'

const BASE_URL = `${process.env.VUE_APP_API_URL}`;

const { usePendingAlertMessage } = useAlertMessage

const useJwtToCreateOption = (options, jwt) => ({
  ...options,
  headers: {
    'Content-Type': 'application/json',
    "Authorization" : `Bearer ${jwt}`
  },
  credentials: "include",
})

async function authFetch(url, options){
  try{
    const auth = useAuth()
    const { postCheckLogin, postLogOut } = auth
    const { jwtToken } = storeToRefs(auth)
    const fetchOption = useJwtToCreateOption(options, jwtToken.value)

    let res = await fetch(BASE_URL + url, fetchOption)

    // unauthorization
    if(res.status === 401){
      const { ok } = await postCheckLogin()
      if(!ok) {
        await postLogOut()
        router.push('/loginIndex')
        return 
      }
      
      const refetchOption = useJwtToCreateOption(options, jwtToken.value)
      res = await fetch(BASE_URL + url, refetchOption)
    }

    return res
  }
  catch{
    return new Error('api errror')
  }
}

async function fetchApi(url, options = {}) {
  const skipPending = options.method === 'GET' ||  options.method === 'get'

  const response = await usePendingAlertMessage(async () => {
    const res = await authFetch(url, options)
    return await res.json();
  }, skipPending)
  
  return response
}

function getTokenFromCookie() {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();

    if (cookie.startsWith('token=')) {
      const token = cookie.substring('token='.length);
      return token;
    }
  }

  return null;
}

export default {
  fetchApi,
  getTokenFromCookie,
};